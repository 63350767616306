import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SurveyForm from './components/SurveyForm'; // Import your SurveyForm component

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Define the route with dynamic surveyId */}
        <Route path="/survey/:surveyId" element={<SurveyForm />} />
        {/* You can add other routes here */}
        {/* Example: */}
        {/* <Route path="/" element={<Home />} /> */}
      </Routes>
    </Router>
  );
};

export default App;
