import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Radio, Checkbox, DatePicker, Upload, message, Modal, Spin } from 'antd';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { WarningOutlined, LockOutlined } from '@ant-design/icons';
import './SurveyForm.css';

const { Option } = Select;

const SurveyForm = () => {
    const { surveyId } = useParams();
    const [surveyData, setSurveyData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false); // State for form submission loading
    const [currentSection, setCurrentSection] = useState(0);
    const [userId, setUserId] = useState(10);
    const [phoneNumber, setPhoneNumber] = useState('');  // To hold phone number value
    const [countryCode, setCountryCode] = useState('+255'); // Default Tanzania country code

    useEffect(() => {
        axios.get(`https://gumbasurvey.com/api/v1/surveys/${surveyId}`)
            .then(response => {
                setSurveyData(response.data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }, [surveyId]);

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;

        // Remove any non-numeric characters (except the first "+" sign for the country code)
        const numericValue = value.replace(/\D/g, '');

        // Allow only up to 9 digits after the country code
        if (numericValue.length <= 9) {
            setPhoneNumber(numericValue);
        }
    };

    const renderQuestion = (question) => {
        if (!question) return null;
        switch (question.type) {
            case 'SHORT':
                return <Input placeholder={question.title} />;
            case 'PARAGRAPH':
                return <Input.TextArea rows={4} placeholder={question.title} />;
            case 'MULTIPLE':
                return (
                    <Radio.Group>
                        {question.answers.map(answer => (
                            <Radio key={answer.id} value={answer.title}>{answer.title}</Radio>
                        ))}
                    </Radio.Group>
                );
            case 'CHECKBOX':
                return (
                    <Checkbox.Group>
                        {question.answers.map(answer => (
                            <Checkbox key={answer.id} value={answer.title}>{answer.title}</Checkbox>
                        ))}
                    </Checkbox.Group>
                );
            case 'DROPDOWN':
                return (
                    <Select placeholder={question.title}>
                        {question.answers.map(answer => (
                            <Option key={answer.id} value={answer.title}>{answer.title}</Option>
                        ))}
                    </Select>
                );
            case 'PHONE':
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Select
                            value={countryCode}
                            onChange={setCountryCode}
                            style={{ width: '90px', marginRight: '8px' }}
                        >
                            <Option value="+255">+255 (TZ)</Option>
                            {/* Add more country options here */}
                        </Select>
                        <Input
                            value={phoneNumber}
                            onChange={handlePhoneNumberChange}
                            placeholder="Enter phone number"
                            maxLength={9}  // Limit input to 9 digits
                            style={{ width: 'calc(100% - 100px)' }}  // Adjust width to make space for the country code selector
                            type="number" // Ensure the input is treated as a number
                        />
                    </div>
                );
            case 'EMAIL':
                return <Input type="email" placeholder="Enter email address" />;
            case 'NUMBER':
                return <Input type="number" placeholder="Enter a number" />;
            case 'FILE':
                return <Upload>
                    <Button>Click to upload</Button>
                </Upload>;
            case 'DATE':
                return <DatePicker placeholder="Select date" />;
            case 'TIME':
                return <DatePicker picker="time" placeholder="Select time" />;
            default:
                return null;
        }
    };

    const handleSubmit = (values) => {
        const payload = {
            userId: userId,
            surveyId: surveyId,
            responses: [],
            form: values,
            location: {
                latitude: "31.0402292",
                longitude: "-7.4893228",
                status: "PENDING"
            },
            imageMaps: []
        };

        // Add phone number with country code to the payload
        if (phoneNumber) {
            payload.form.phoneNumber = countryCode + phoneNumber;
        }

        for (let key in values) {
            const question = surveyData.sections.flatMap(section => section.questions).find(q => q.title === key);
            if (question) {
                const response = {
                    questionNumber: question.id,
                    answer: Array.isArray(values[key]) ? values[key].join(', ') : values[key]
                };
                payload.responses.push(response);
            }
        }

        setSubmitting(true); // Start showing loading modal

        axios.post(`https://gumbasurvey.com/api/v1/responses/${userId}/${surveyId}`, payload)
            .then(response => {
                message.success('Response submitted successfully');
                
                // Show confirmation modal
                Modal.confirm({
                    title: 'Survey Submitted',
                    content: 'Would you like to fill another survey?',
                    okText: 'Yes',
                    cancelText: 'No',
                    onOk: () => {
                        // Refresh the page
                        window.location.reload();
                    },
                });
            })
            .catch(error => {
                message.error('Failed to submit response');
            })
            .finally(() => {
                setSubmitting(false); // Stop showing loading modal
            });
    };

    const handleNext = () => {
        if (currentSection < surveyData.sections.length - 1) {
            setCurrentSection(currentSection + 1);
        }
    };

    const handlePrevious = () => {
        if (currentSection > 0) {
            setCurrentSection(currentSection - 1);
        }
    };

    if (loading) {
        return (
            <div className="loading-container">
                <img src="/assets/images/logo.png" alt="Loading..." className="loading-logo" />
                <p>Loading Survey Data.....</p>
            </div>
        );
    }

    if (!surveyData) {
        return (
            <div style={{ fontSize: '24px', color: '#888', textAlign: 'center', marginTop: '20px' }}>
                <WarningOutlined style={{ fontSize: '48px', color: '#ff4d4f' }} />
                <p>No survey data available</p>
            </div>
        );
    }

    if (surveyData.accessibility !== 'PUBLIC') {
        return (
            <div style={{ fontSize: '24px', color: '#888', textAlign: 'center', marginTop: '20px' }}>
                <LockOutlined style={{ fontSize: '48px', color: '#ff4d4f' }} />
                <p>This survey is protected</p>
            </div>
        );
    }

    const currentSectionData = surveyData.sections[currentSection];

    return (
        <div className="form-container">
            <Form onFinish={handleSubmit}>
                <h2>{surveyData.title || 'Survey Title'}</h2>
                <p>{surveyData.description || 'Survey Description'}</p>
                <div className="form-section">
                    <h3>{currentSectionData.title}</h3>
                    <p>{currentSectionData.subtitle}</p>
                    {currentSectionData.questions && currentSectionData.questions.map(question => (
                        <Form.Item
                            key={question.id}
                            name={question.title}
                            label={question.title}
                            rules={[{ required: question.isRequired, message: `${question.title} is required` }]}
                        >
                            {renderQuestion(question)}
                        </Form.Item>
                    ))}
                </div>

                <div className="form-navigation">
                    {currentSection > 0 && (
                        <Button onClick={handlePrevious} className="prev-btn">Previous</Button>
                    )}
                    {currentSection < surveyData.sections.length - 1 ? (
                        <Button type="primary" onClick={handleNext} className="next-btn">Next</Button>
                    ) : (
                        <Button type="primary" htmlType="submit" className="submit-btn">Submit</Button>
                    )}
                </div>
            </Form>

            {/* Modal for loading indication */}
            <Modal
                visible={submitting}
                footer={null}
                closable={false}
                centered
                bodyStyle={{ textAlign: 'center' }}
            >
                <Spin size="large" />
                <p style={{ marginTop: 16 }}>Data Processing...</p>
            </Modal>
        </div>
    );
};

export default SurveyForm;
