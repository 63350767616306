// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Loading screen styles */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.loading-logo {
  width: 150px;
  height: auto;
  margin-bottom: 20px;
}

/* Form container and layout */
.form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  font-family: Arial, sans-serif;
}

/* Additional styling */
.form-section {
  margin-bottom: 24px;
  padding: 16px;
  border-radius: 8px;
  background-color: #f7f8fa;
}

.form-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.prev-btn,
.next-btn,
.submit-btn {
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 4px;
}

.prev-btn {
  background-color: #e0e0e0;
  color: #3c4043;
}

.next-btn,
.submit-btn {
  background-color: #1a73e8;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/SurveyForm.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,mBAAmB;AACrB;;AAEA,8BAA8B;AAC9B;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,kBAAkB;EAClB,yCAAyC;EACzC,yBAAyB;EACzB,8BAA8B;AAChC;;AAEA,uBAAuB;AACvB;EACE,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;;;EAGE,eAAe;EACf,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;;EAEE,yBAAyB;EACzB,YAAY;AACd","sourcesContent":["/* Loading screen styles */\n.loading-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  background-color: #f0f2f5;\n}\n\n.loading-logo {\n  width: 150px;\n  height: auto;\n  margin-bottom: 20px;\n}\n\n/* Form container and layout */\n.form-container {\n  max-width: 600px;\n  margin: 0 auto;\n  padding: 20px;\n  border-radius: 8px;\n  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n  background-color: #ffffff;\n  font-family: Arial, sans-serif;\n}\n\n/* Additional styling */\n.form-section {\n  margin-bottom: 24px;\n  padding: 16px;\n  border-radius: 8px;\n  background-color: #f7f8fa;\n}\n\n.form-navigation {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 20px;\n}\n\n.prev-btn,\n.next-btn,\n.submit-btn {\n  font-size: 16px;\n  padding: 10px 20px;\n  border-radius: 4px;\n}\n\n.prev-btn {\n  background-color: #e0e0e0;\n  color: #3c4043;\n}\n\n.next-btn,\n.submit-btn {\n  background-color: #1a73e8;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
